/* @font-face {
	font-family: RalewayModified;
	src: url(https://designsystem.ontario.ca/fonts/raleway-modified-400/raleway-modified-400.woff);
}

@font-face {
	font-family: OpenSans;
	src: url(https://designsystem.ontario.ca/fonts/open-sans-400/open-sans-400.ttf);
} */

body {
	margin: 0;
	font-family: "Raleway", "Open Sans", "Helvetica Neue", "Sans Serif" !important;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

p,
button,
a,
li,
h1,
h2,
h3,
h4,
h5,
h6,
div,
label {
	font-family: "Raleway", "Open Sans", "Helvetica Neue", "Sans Serif" !important;
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

.sup {
	font-size: 10px;
	position: relative;
	bottom: 6px;
	padding-left: 0px;
}

.no-word-break {
	white-space: nowrap;
}
